import React, { useEffect, useState } from "react";
import "./DateTimeDisplay.css";

const DateTimeDisplay = () => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update current date and time every second
      setCurrentDateTime(new Date());
    }, 1000 * 30); // Update every minute (1000 * 60 milliseconds)

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const hours = currentDateTime.getHours().toString().padStart(2, "0"); // Get hours and pad with leading zero if needed
  const minutes = currentDateTime.getMinutes().toString().padStart(2, "0"); // Get minutes and pad with leading zero if needed

  // Define options for formatting the date
  const options = {
    weekday: "short", // Abbreviated day of the week (e.g., "Mon")
    day: "2-digit", // Two-digit day of the month (e.g., "22")
    month: "short", // Abbreviated month name (e.g., "Feb")
    year: "numeric", // Full year (e.g., "2024")
  };

  // Format the date using toLocaleDateString method
  const currentDate = currentDateTime.toLocaleDateString("en-UK", options);
  const currentTime = `${hours}:${minutes}`;

  return (
    <div>
      <div className="container-title">{currentDate}</div>
      <div className="hero-time">{currentTime}</div>
    </div>
  );
};

export default DateTimeDisplay;
