import "./Profile.css";
import image from "../../assets/testemonial-3.jpeg";

const Profile = ({ loggedUser }) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  return (
    <div className="profile page">
      <div className="page-container">
        <div className="page-header">
          <div className="page-title">Profile</div>
        </div>
        <div className="page-main">
          {" "}
          <div className="profile-container container">
            <div className="container-header">
              <div className="container-title">Profile Data</div>
            </div>
            <div className="container-main">
              <div className="profile-main-data">
                {loggedUser.employee ? (
                  <>
                    <div className="profile-row">
                      <div className="profile-row-label"></div>
                      <b>Name: </b>{" "}
                      {`${loggedUser.employee.firstName} ${loggedUser.employee.lastName}`}
                    </div>
                    <div className="profile-row">
                      <b>Email:</b> {loggedUser.email}
                    </div>
                    <div className="profile-row">
                      <b>Address:</b>{" "}
                      {`${loggedUser.employee.street}, ${loggedUser.employee.zip} ${loggedUser.employee.city}`}
                    </div>
                    <div className="profile-row">
                      <b>Country:</b> {loggedUser.employee.country}
                    </div>
                    <div className="profile-row">
                      <b>Phone:</b> {loggedUser.employee.phone}
                    </div>
                    <div className="profile-row">
                      <b>Start Date:</b>{" "}
                      {new Date(
                        loggedUser.employee.startDate
                      ).toLocaleDateString(undefined, options)}
                    </div>
                    <div className="profile-row">
                      <b>Salary:</b>{" "}
                      {`${loggedUser.employee.salary} EUR / month`}
                    </div>
                    <div className="profile-row">
                      <b>User ID:</b> {`${loggedUser._id}`}
                    </div>
                    <div className="profile-row">
                      <b>Employee ID:</b> {`${loggedUser.employee._id}`}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="profile-main-img">
                <img src={image} alt="" className="profile-main-img-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
