import React, { useEffect, useState } from "react";
import "./HeroActivityLog.css";
import BASE_URL from "../../apiConfig"; // Adjust the path as needed

const HeroActivityLog = ({ employeeStatus, employeeId }) => {
  const [allTodayRecords, setAllTodayRecords] = useState([]);
  const optionsTime = { hour: "2-digit", minute: "2-digit" };
  const optionsDate = { day: "2-digit", month: "short" };

  useEffect(() => {
    const fetchAllTodayRecords = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/employees/${employeeId}/timerecords-today`
        );
        const data = await response.json();
        // Assuming the employee status is returned in the response data
        setAllTodayRecords(data.allRecords);
      } catch (error) {
        console.error("Error:", error);
        // Handle error
      }
    };

    // Fetch employee status when the component mounts
    fetchAllTodayRecords();
  }, [employeeStatus, employeeId]); // Include employeeStatus and employeeId in the dependency array

  return (
    <div className="container">
      <div className="container-title">Today's Activity Log</div>
      <div className="hero-activities-container">
        <div className="hero-activities">
          {(!allTodayRecords && <span>No Records Yet.</span>) ||
            allTodayRecords.map((item, index) => (
              <div className="hero-activity" key={index}>
                <div className="hero-activity-timestamp">
                  <div className="hero-activity-timestamp-time">
                    {new Date(item.timestamp).toLocaleDateString(
                      [],
                      optionsDate
                    )}
                  </div>
                  <div className="hero-activity-timestamp-date">
                    {new Date(item.timestamp).toLocaleTimeString(
                      [],
                      optionsTime
                    )}
                  </div>
                </div>
                <div className={`${item.type} hero-activity-type bg-clockin`}>
                  {item.type === "clockin"
                    ? "Clock In"
                    : item.type === "clockout"
                    ? "Clock Out"
                    : item.type === "startbreak"
                    ? "Start Break"
                    : item.type === "endbreak"
                    ? "End Break"
                    : ""}
                </div>
                <div className="hero-activity-icon">
                  <i className="uil uil-angle-right-b"></i>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HeroActivityLog;
