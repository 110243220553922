import "./HeroClockInOut";
import DateTimeDisplay from "../DateTimeDisplay/DateTimeDisplay";
import "./HeroClockInOut.css";

const HeroClockInOut = ({
  employeeStatus,
  setEmployeeStatus,
  isButton1Disabled,
  handleClockOut,
  handleClockIn,
  isButton2Disabled,
  handleBreakStart,
  handleBreakEnd,
  activeTimeToday,
  BreakTimeToday,
  openModal,
}) => {
  const formatHoursToTime = (hours) => {
    const hoursPart = Math.floor(hours); // Get the whole number part (hours)
    const minutesPart = Math.round((hours - hoursPart) * 60); // Convert the decimal part to minutes

    return `${hoursPart.toString().padStart(2, "0")}h ${minutesPart
      .toString()
      .padStart(2, "0")}m`;
  };

  return (
    <div className="container">
      <DateTimeDisplay />

      <div className="hero-clockin-container">
        <button
          disabled={isButton1Disabled}
          onClick={() =>
            openModal(employeeStatus === "active" ? "clockout" : "clockin")
          }
          //   onClick={employeeStatus === "active" ? handleClockOut : handleClockIn}
          className={
            employeeStatus === "active"
              ? "hero-clockin-button hero-button status-inactive"
              : employeeStatus === "break"
              ? "hero-clockin-button hero-button status-active disabled"
              : "hero-clockin-button hero-button status-active"
          }
        >
          <i className="uil uil-clock-eight"></i>
          <span>
            {employeeStatus === "active" || employeeStatus === "break"
              ? "Clock Out"
              : "Clock In"}
          </span>
        </button>

        <button
          disabled={isButton2Disabled}
          onClick={() =>
            openModal(employeeStatus === "active" ? "startbreak" : "endbreak")
          }
          //   onClick={
          //     employeeStatus === "active" ? handleBreakStart : handleBreakEnd
          //   }
          className={
            employeeStatus === "inactive"
              ? "hero-break-button hero-button status-break disabled"
              : "hero-break-button hero-button status-break"
          }
        >
          <i className="uil uil-coffee"></i>
          <span>
            {employeeStatus === "break" ? "End Break" : "Start Break"}
          </span>
        </button>
      </div>
      <div className="hero-stats">
        <div className="hero-stats-main">
          <div className="hero-stats-hours">
            <div className="hero-stats-hours-title">Work Time: </div>
            <div className="hero-stats-hours-text">
              {formatHoursToTime(activeTimeToday - BreakTimeToday)}
            </div>
          </div>
          <div className="hero-stats-hours">
            <div className="hero-stats-hours-title">Break Time: </div>
            <div className="hero-stats-hours-text">
              {formatHoursToTime(BreakTimeToday)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroClockInOut;
