import { useEffect, useState } from "react";
import "./App.css";
import Header from "./components/header/Header";
import Home from "./pages/home/Home";
import Sidebar from "./components/sidebar/Sidebar";
import BASE_URL from "./apiConfig"; // Adjust the path as needed
import { BrowserRouter, Route, Routes } from "react-router-dom"; // Import Navigate
import Dashboard from "./pages/dashboard/Dashboard";
import Schedule from "./pages/schedule/Schedule";
import Profile from "./pages/profile/Profile";
import Users from "./pages/users/Users";
import Tasks from "./pages/tasks/Tasks";
import Login from "./components/login/Login";
import { jwtDecode } from "jwt-decode";

function App() {
  const [employeeStatus, setEmployeeStatus] = useState("inactive");
  const [smallSidebar, setSmallSidebar] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loggedUser, setLoggedUser] = useState("");

  useEffect(() => {
    // Check if JWT token exists in localStorage
    const token = localStorage.getItem("token");

    if (token) {
      const user = jwtDecode(token); // decode your token here
      // console.log(user);
      const userId = user.userId; // Assuming the ID is stored in a property called userId
      setLoggedIn(true);
      fetchUser(userId);
    }

    // Fetch employee status when the component mounts
  }, [loggedIn]);

  // Fetch employee details including employeeId
  const fetchUser = async (userId) => {
    try {
      const response = await fetch(`${BASE_URL}/users/${userId}`);
      const userData = await response.json();
      // Assuming employeeId is an attribute of the user object
      const user = userData;
      // console.log("User:", user);
      setLoggedUser(user);
      setEmployeeStatus(user.employee.activeStatus);
      // Further actions with employeeId
    } catch (error) {
      console.error("Error fetching employeeId:", error);
    }
  };

  const toggleSidebar = () => {
    setSmallSidebar(!smallSidebar);
  };

  // Function to set the logged-in employee ID
  const setLoggedInEmployee = (employeeId) => {
    setLoggedIn(!loggedIn);
  };

  const logout = () => {
    // Remove JWT token from localStorage
    localStorage.removeItem("token");
    // Set loggedIn state to false
    setLoggedIn(false);
    setLoggedUser("");
  };

  return (
    <div className="App">
      {loggedUser ? (
        <div className="app-container">
          <BrowserRouter>
            <Header
              toggleSidebar={toggleSidebar}
              loggedUser={loggedUser}
              employeeStatus={employeeStatus}
            />
            <Sidebar
              smallSidebar={smallSidebar}
              logout={logout} // Pass logout function to Header component
            />
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    employeeStatus={employeeStatus}
                    setEmployeeStatus={setEmployeeStatus}
                    loggedUser={loggedUser}
                  />
                }
              />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/schedule" element={<Schedule />} />
              <Route
                path="/profile"
                element={<Profile loggedUser={loggedUser} />}
              />
              <Route path="/users" element={<Users />} />
              <Route path="/tasks" element={<Tasks />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </BrowserRouter>{" "}
        </div>
      ) : (
        <Login setLoggedInEmployee={setLoggedInEmployee} />
      )}
    </div>
  );
}

export default App;
