import React, { useState } from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";

const Sidebar = ({ smallSidebar, logout }) => {
  const [activeTab, setActiveTab] = useState("home");

  const handleTab = (tab) => {
    setActiveTab(tab);
    console.log(activeTab);
  };
  const renderSidebarItem = (to, iconClass, title) => {
    return (
      <Link
        className={
          activeTab === to ? "sidebar-item sidebar-active" : "sidebar-item"
        }
        to={`/${to}`}
        onClick={() => handleTab(to)}
      >
        <i className={iconClass}></i>{" "}
        {!smallSidebar && <div className="sidebar-item-title">{title}</div>}
      </Link>
    );
  };

  return (
    <div className={smallSidebar ? "sidebar small-sidebar" : "sidebar"}>
      <div className="sidebar-container">
        <div className="sidebar-items">
          {renderSidebarItem("", "bx bx-home-alt", "Home")}
          {renderSidebarItem("dashboard", "bx bxs-dashboard", "Dashboard")}
          {renderSidebarItem("schedule", "bx bx-calendar", "Schedule")}
          {renderSidebarItem("profile", "bx bx-user", "Profile")}
          <hr></hr>
          {renderSidebarItem("tasks", "bx bx-task", "Tasks")}
          {renderSidebarItem("users", "bx bxs-user-account", "Users")}
        </div>
        <div className="sidebar-items">
          <div className="sidebar-logout">
            <div onClick={logout}>
              {!smallSidebar && (
                <Link className="sidebar-item" to={`/`}>
                  <i className="bx bx-log-out-circle"></i>

                  {!smallSidebar && (
                    <div className="sidebar-item-title">Log Out</div>
                  )}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
