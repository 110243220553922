// Modal.js

import React from "react";
import "./Modal.css";

const Modal = ({ isOpen, onClose, title, text, onConfirm, icon }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-icon">
          <i className={icon}></i>
        </div>

        <h2>{title}</h2>
        <p>{text}</p>
        <div className="modal-buttons">
          <button className="modal-close-btn button-cancel" onClick={onClose}>
            Cancel
          </button>
          <button className="modal-close-btn button-yes" onClick={onConfirm}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
