import "./Header.css";
import image from "../../assets/testemonial-3.jpeg";
import image2 from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Header = ({ toggleSidebar, loggedUser, employeeStatus }) => {
  // Use useEffect to listen for changes in employeeStatus
  useEffect(() => {
    // This effect will re-run whenever employeeStatus changes
    console.log("Employee status changed:", employeeStatus);
  }, [employeeStatus]);
  return (
    <div className="header">
      <div className="header-left">
        {" "}
        <button className="burger-button" onClick={toggleSidebar}>
          <i className="bx bx-menu"></i>
        </button>
        <Link className="header-logo" to="/home">
          {" "}
          <img src={image2} alt="" className="header-logo-img" />
        </Link>
      </div>

      <div className="header-container">
        <div className="header-status">
          <div
            className={
              employeeStatus === "active"
                ? "header-status-dot header-active-bg"
                : employeeStatus === "break"
                ? "header-status-dot header-break-bg"
                : "header-status-dot header-inactive-bg"
            }
          ></div>
          <div
            className={
              employeeStatus === "active"
                ? "header-status-text header-active"
                : employeeStatus === "break"
                ? "header-status-text header-break"
                : "header-status-text header-inactive"
            }
          >
            {employeeStatus === "active"
              ? "ACTIVE"
              : employeeStatus === "break"
              ? "ON BREAK"
              : "INACTIVE"}
          </div>
        </div>
        <Link className="header-user" to="/profile">
          {" "}
          <div className="header-user-text">{`${loggedUser.employee.firstName} ${loggedUser.employee.lastName}`}</div>
          <img src={image} alt="" className="header-user-img" />{" "}
        </Link>{" "}
      </div>
    </div>
  );
};

export default Header;
