import React, { useEffect, useState } from "react";
import "./Users.css";
import BASE_URL from "../../apiConfig"; // Adjust the path as needed
import image from "../../assets/testemonial-3.jpeg";

const Users = () => {
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    // Fetch employee status when the component mounts
    fetchAllUsers();
  }, []); // Re-fetch status when employeeId changes

  const fetchAllUsers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/users`);
      const data = await response.json();
      // Assuming the employee status is returned in the response data
      setAllUsers(data);
      // console.log(data);
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };
  return (
    <div className="users page">
      <div className="page-container">
        <div className="page-header">
          <div className="page-title">Users</div>
        </div>
        <div className="page-main">
          {" "}
          <div className="users-container container">
            {/* <div className="container-header">
              <div className="container-title">All Users</div>
            </div> */}
            <div className="container-main">
              <table className="user-table">
                <thead>
                  <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>User ID</th>
                    <th>Status</th>
                    <th>Work Time</th>
                    <th>Break Time</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <img src={image} alt="User" className="user-picture" />
                      </td>
                      <td>{`${user.employee.firstName} ${user.employee.lastName}`}</td>
                      <td>{user.email}</td>
                      <td>{user._id}</td>
                      <td>
                        <div
                          className={
                            user.employee.activeStatus === "active"
                              ? "users-status active-status"
                              : user.employee.activeStatus === "inactive"
                              ? "users-status inactive-status"
                              : "users-status break-status"
                          }
                        >
                          {user.employee.activeStatus}
                        </div>
                      </td>
                      <td>2:18</td>
                      <td>0:14</td>
                      <td>
                        <i className="bx bx-dots-vertical-rounded users-icon"></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
