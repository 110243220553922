import React, { useState, useEffect } from "react";
import "./Login.css";
import image2 from "../../assets/logo.png";
import BASE_URL from "../../apiConfig";

const PWD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;

const USER_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Login = ({ setLoggedInEmployee }) => {
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [validName, setValidName] = useState(false);
  const [validPwd, setValidPwd] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  // const [success, setSuccess] = useState(false);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
  }, [pwd]);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValidUser = USER_REGEX.test(user);
    const isValidPwd = PWD_REGEX.test(pwd);

    if (!isValidUser || !isValidPwd) {
      setErrMsg("Invalid Entry");
      return;
    }

    const body = JSON.stringify({ email: user, password: pwd });
    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });

      if (!response.ok) {
        // Handle unsuccessful login
        setErrMsg("Invalid username or password");
        return;
      }

      const data = await response.json();
      localStorage.setItem("token", data.token);
      // setSuccess(true);

      // If login is successful, toggleLoggedIn to render the main app
      setLoggedInEmployee();
    } catch (error) {
      console.error("Login error:", error);
      // Handle login error
    }
  };

  return (
    <div className="login">
      <div className="login-container">
        <div className="header-logo">
          <img src={image2} alt="Logo" className="header-logo-img" />
        </div>
        <h1 className="login-title">Log In</h1>
        <form onSubmit={handleSubmit}>
          <div className="login-fields">
            <input
              type="email"
              id="username"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              required
              aria-invalid={!validName}
              aria-describedby="uidnote"
              placeholder="Email Address"
            />
            <input
              type="password"
              id="password"
              value={pwd}
              onChange={(e) => setPwd(e.target.value)}
              required
              aria-invalid={!validPwd}
              aria-describedby="pwdnote"
              placeholder="Password"
            />
          </div>
          <button disabled={!user || !pwd}>Log In</button>
          <div className="error-message-container">
            {" "}
            {errMsg && (
              <>
                <i className="bx bx-x-circle"></i>
                <div className="error-message">{errMsg}</div>
              </>
            )}
          </div>{" "}
        </form>
      </div>
    </div>
  );
};

export default Login;
