import React from "react";

const Schedule = () => {
  return (
    <div className="schedule page">
      <div className="page-container">
        <div className="page-header">
          <div className="page-title">Schedule</div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
