import React from "react";
import "./Dashboard.css";

const Dashboard = () => {
  return (
    <div className="dashboard page">
      <div className="home-container page-container">
        <div className="page-header">
          <div className="page-title">Dashboard</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
