import "./Home.css";

import Heroes from "../../components/heroes/Heroes";
import HeroTasks from "../../components/heroTasks/HeroTasks";

const Home = ({ employeeStatus, setEmployeeStatus, loggedUser }) => {
  return (
    <div className="home page">
      <div className="home-container page-container">
        <div className="page-header">
          <div className="page-title">Home</div>
        </div>

        <div className="page-main">
          <div className="home-heroes">
            <Heroes
              employeeStatus={employeeStatus}
              setEmployeeStatus={setEmployeeStatus}
              loggedUser={loggedUser}
            />
          </div>
          <div className="home-heroes">
            <div className="home-hero">
              <HeroTasks loggedUser={loggedUser} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
