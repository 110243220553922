import React, { useEffect, useState } from "react";
import HeroClockInOut from "../HeroClockInOut/HeroClockInOut";
import HeroActivityLog from "../HeroActivityLog/HeroActivityLog";
import BASE_URL from "../../apiConfig"; // Adjust the path as needed
import Modal from "../modal/Modal";

const Heroes = ({ employeeStatus, setEmployeeStatus, loggedUser }) => {
  const [isButton1Disabled, setIsButton1Disabled] = useState(false);
  const [isButton2Disabled, setIsButton2Disabled] = useState(false);

  // const employeeId = loggedUser.employee._id;
  const employeeId = "65da88cdc8cc2dd28e0f70d8";

  useEffect(() => {
    setIsButton1Disabled(employeeStatus === "break");
    setIsButton2Disabled(employeeStatus === "inactive");
  }, [employeeStatus]);

  const handleClockIn = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/employees/${employeeId}/clockin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //   const data = await response.json();
      //   console.log(data);
      // Update employee status to "active" if clock-in is successful
      if (response.ok) {
        setEmployeeStatus("active");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClockOut = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/employees/${employeeId}/clockout`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //   const data = await response.json();
      //   console.log(data);
      // Update employee status to "inactive" if clock-out is successful
      if (response.ok) {
        setEmployeeStatus("inactive");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBreakStart = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/employees/${employeeId}/startbreak`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //   const data = await response.json();
      //   console.log(data);
      // Update employee status to "break" if start-break is successful
      if (response.ok) {
        setEmployeeStatus("break");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBreakEnd = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/employees/${employeeId}/endbreak`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //   const data = await response.json();
      //   console.log(data);
      // Update employee status to "active" if end-break is successful
      if (response.ok) {
        setEmployeeStatus("active");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [activeTimeToday, setActiveTimeToday] = useState(0);
  const [BreakTimeToday, setBreakTimeToday] = useState(0);

  useEffect(() => {
    fetchActiveTime();
    fetchBreakTime();
    const intervalId = setInterval(() => {
      // Update current date and time every second
      fetchActiveTime();
      fetchBreakTime();
    }, 1000 * 60); // Update every minute (1000 * 60 milliseconds)

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const fetchActiveTime = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/employees/${employeeId}/active-hours-today`
      );
      const data = await response.json();
      // Assuming the employee status is returned in the response data
      //   console.log(data.totalActiveHours);
      setActiveTimeToday(data.totalActiveHours);
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  const fetchBreakTime = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/employees/${employeeId}/break-hours-today`
      );
      const data = await response.json();
      // Assuming the employee status is returned in the response data
      //   console.log(data.totalBreakHours);
      setBreakTimeToday(data.totalBreakHours);
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  const [modalContent, setModalContent] = useState({
    isOpen: false,
    title: "",
    text: "",
    onConfirm: null,
    icon: "",
  });

  const openModal = (action) => {
    let title = "";
    let text = "";
    let onConfirm = null;
    let icon = "";

    // Determine modal content based on the action
    switch (action) {
      case "clockin":
        title = "Clock In";
        text = "Are you sure you want to Clock In?";
        onConfirm = () => {
          handleClockIn();
          closeModal();
        };
        icon = "uil uil-clock-eight";
        break;
      case "clockout":
        title = "Clock Out";
        text = "Are you sure you want to Clock Out?";
        onConfirm = () => {
          handleClockOut();
          closeModal();
        };
        icon = "uil uil-clock-eight";
        break;
      case "startbreak":
        title = "Start Break";
        text = "Are you sure you want to Start Break?";
        onConfirm = () => {
          handleBreakStart();
          closeModal();
        };
        icon = "uil uil-coffee";
        break;
      case "endbreak":
        title = "End Break";
        text = "Are you sure you want to End Break?";
        onConfirm = () => {
          handleBreakEnd();
          closeModal();
        };
        icon = "uil uil-coffee";
        break;
      default:
        break;
    }

    // Update modal content state
    setModalContent({
      isOpen: true,
      title: title,
      text: text,
      icon: icon,
      onConfirm: onConfirm,
    });
  };

  const closeModal = () => setModalContent({ ...modalContent, isOpen: false });

  return (
    <>
      <div className="home-hero">
        <HeroClockInOut
          employeeStatus={employeeStatus}
          setEmployeeStatus={setEmployeeStatus}
          isButton1Disabled={isButton1Disabled}
          handleClockOut={handleClockOut}
          handleClockIn={handleClockIn}
          isButton2Disabled={isButton2Disabled}
          handleBreakStart={handleBreakStart}
          handleBreakEnd={handleBreakEnd}
          activeTimeToday={activeTimeToday}
          BreakTimeToday={BreakTimeToday}
          openModal={openModal}
        />
      </div>
      <div className="home-hero">
        <HeroActivityLog
          employeeStatus={employeeStatus}
          employeeId={employeeId}
        />
      </div>
      <Modal
        isOpen={modalContent.isOpen}
        onClose={closeModal}
        title={modalContent.title}
        text={modalContent.text}
        onConfirm={modalContent.onConfirm}
        icon={modalContent.icon}
      ></Modal>
    </>
  );
};

export default Heroes;
