import React from "react";

const HeroTasks = ({ loggedUser }) => {
  return (
    <div className="hero-tasks container">
      <div className="container-title">Tasks</div>
      <div className="tasks">
        <div className="task">Task1</div>
        <div className="task">Task2</div>
        <div className="task">Task3</div>
      </div>
    </div>
  );
};

export default HeroTasks;
